import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
import Cookies from "js-cookie";
export const HCPApi = createApi({
  reducerPath: "HCP",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["HCP , FollowStatus"],

  endpoints: (builder) => ({
    getHCPListing: builder.query({
      query: (params) => ({
        url: `/professionals`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      providesTags: ["HCP"],
    }),

    getMostActive: builder.query({
      query: (params) => ({
        url: `/professionals/most-active`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      providesTags: ["HCP"],
    }),

    getHCPDetails: builder.query({
      query: (params) => ({
        url: `/professionals/${params}`,
      }),
      providesTags: ["HCP"],
    }),

    // followProfile: builder.mutation({
    //   query: (params) => ({
    //     method: "POST",
    //     url: `/follow/${params}`,
    //   }),
    //   invalidatesTags: ["HCP"],
    // }),

    connectPorile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/connections/send/${params}`,
      }),
      invalidatesTags: ["HCP"],
    }),

    getUserNetwork: builder.query({
      query: (params) => ({
        url: `/connections`,
        method: "GET",
        params,
      }),
      providesTags: ["HCP"],
    }),

    handleConnections: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/connections/handle/${params?.id}`,
        params,
      }),
      invalidatesTags: ["HCP"],
    }),

    //Bio
    addProfileBio: builder.mutation({
      query: (params) => ({
        url: `/profile/bio/create`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    updateProfileBio: builder.mutation({
      query: (params) => ({
        url: `/profile/bio/update`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      invalidatesTags: ["HCP"],
    }),

    // Education

    addProfileEducation: builder.mutation({
      query: (params) => ({
        url: `/profile/education/create`,
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    updateProfileEducation: builder.mutation({
      query: (params) => ({
        url: `/profile/education/update/${params?.id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    deleteProfileEducation: builder.mutation({
      query: (params) => ({
        url: `/profile/education/delete/${params}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      invalidatesTags: ["HCP"],
    }),

    getEducationTags: builder.query({
      query: () => ({
        method: "GET",
        url: `/profile/education/tags`,
      }),
      providesTags: ["HCP"],
    }),

    // Courses
    addProfileCourses: builder.mutation({
      query: (params) => ({
        url: `/profile/course/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    updateProfileCourse: builder.mutation({
      query: (params) => ({
        url: `/profile/course/update/${params?.id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    deleteProfileCourse: builder.mutation({
      query: (params) => ({
        url: `/profile/course/delete/${params}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      invalidatesTags: ["HCP"],
    }),

    getCourseTags: builder.query({
      query: () => ({
        url: `/profile/course/tags`,
      }),
      providesTags: ["HCP"],
    }),

    //Position
    addProfilePosition: builder.mutation({
      query: (params) => ({
        url: `/profile/position/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    updateProfilePosition: builder.mutation({
      query: (params) => ({
        url: `/profile/position/update/${params?.id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["HCP"],
    }),

    deleteProfilePosition: builder.mutation({
      query: (params) => ({
        url: `/profile/position/delete/${params}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      invalidatesTags: ["HCP"],
    }),

    getPositionTags: builder.query({
      query: () => ({
        url: `/profile/position/tags`,
      }),
      providesTags: ["HCP"],
    }),

    //Like

    likeProfile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/users/like`,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["HCP"],
    }),

    dislikeProfile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/users/dislike`,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["HCP"],
    }),

    countProfileLikes: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/users/count-likes/${params}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      providesTags: ["HCP"],
    }),

    //Bookmark

    bookmarkProfile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/users/bookmarks/add`,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["HCP"],
    }),

    removeBookmarkProfile: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/users/bookmarks/remove`,
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["HCP"],
    }),

    countProfileBookmarks: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/users/bookmarks/count/${params}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      invalidatesTags: ["HCP"],
    }),

    //Update Profile Card
    updateProfileCard: builder.mutation({
      query: (params) => ({
        url: `/profile/update`,
        method: "POST",
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   Accept: "application/json",
        //   Authorization: `Bearer ${Cookies.get("access_token")}`,
        // },
        body: params,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["HCP"],
    }),

    //get all profiles tags
    getProfilesTags: builder.query({
      query: () => ({
        method: "GET",
        url: `/profile/tags`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }),
      invalidatesTags: ["HCP"],
    }),

    getMutualConnections: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/professionals/mutual-connections`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      invalidatesTags: ["HCP"],
    }),

    getUserFollowers: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/followers`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      invalidatesTags: ["HCP"],
    }),
  }),
});

export const {
  useGetHCPListingQuery,
  useGetMostActiveQuery,
  useGetHCPDetailsQuery,
  // useFollowProfileMutation,
  useConnectPorileMutation,
  useGetUserNetworkQuery,
  useHandleConnectionsMutation,

  useAddProfileBioMutation,
  useUpdateProfileBioMutation,

  useAddProfileEducationMutation,
  useUpdateProfileEducationMutation,
  useDeleteProfileEducationMutation,
  useGetEducationTagsQuery,

  useAddProfileCoursesMutation,
  useUpdateProfileCourseMutation,
  useDeleteProfileCourseMutation,
  useGetCourseTagsQuery,

  useAddProfilePositionMutation,
  useUpdateProfilePositionMutation,
  useDeleteProfilePositionMutation,
  useGetPositionTagsQuery,

  useLikeProfileMutation,
  useDislikeProfileMutation,
  useCountProfileLikesQuery,
  useBookmarkProfileMutation,
  useRemoveBookmarkProfileMutation,
  useCountProfileBookmarksQuery,

  useUpdateProfileCardMutation,
  useGetProfilesTagsQuery,

  useGetMutualConnectionsQuery,
  useGetUserFollowersQuery,
} = HCPApi;
