export const validateInput = (value) => {
  const unsafeInputRegex =
    /(<script\b[^>]*>.*?<\/script>|<\/?\w+[^>]*>|style\s*=\s*["'][^"']*["']|javascript:|data:text\/html|select\s+\*\s+from\s+\w+|insert\s+into\s+\w+\s+\(.*\)\s+values\s+\(.*\)|update\s+\w+\s+set\s+.*=.*|delete\s+from\s+\w+|drop\s+table\s+\w+|union\s+select\s+.*|;\s*|--|\/\*.*?\*\/|#|\$)/i;

  const containsString = /[a-zA-Z]/.test(value);
  const containsNumber = /[0-9]/.test(value);
  const stringValue = value?.trim();

  if (!containsString || (!containsString && !containsNumber)) {
    return "Input cannot be only numerical";
  }

  if (stringValue?.length < 10 || stringValue?.length > 250) {
    return "Input length must be between 10 and 250 characters";
  }

  if (unsafeInputRegex?.test(value)) {
    return "Invalid input: Potentially harmful content detected.";
  }

  return null;
};
