import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Popover } from "@mantine/core";
import { useAuth } from "../Context/authContext";
import Cookies from "js-cookie";

import "./header.css";
import MegaMenu from "./MegaMenu";
import SideMenu from "./SideMenu";
import AfterLogin from "./AfterLogin";
import HeaderSearchInput from "../Pages/Search/HeaderSearchInput";

import Logo from "../assets/images/logo.svg";
import Menu from "../assets/images/Menu.svg";

import { useGetNotificationsListQuery } from "../store/api/Home/NotificationApi";
import { useGetScoringPointsQuery } from "../store/api/ScoringPoints/ScoringPointsApi";

export default function Header() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading, error } = useGetNotificationsListQuery();
  const { data: getScoringPoints } = useGetScoringPointsQuery();

  return (
    <>
      <nav className="bg-white border-b border-gray-200 p-2">
        <div className="w-full px-4 py-2 flex justify-between items-center">
          {/* First Part: Menu and Logo */}
          <div className="flex items-center space-x-4">
            {/* <Popover
              width={300}
              trapFocus
              position="bottom"
              withArrow
              shadow="md"
              className="hidden lg:block"
            >
              <Popover.Target >
                <img src={Menu} className="w-6 h-6 cursor-pointer" alt="Menu" />
              </Popover.Target>
              <Popover.Dropdown className="p-3 !w-11/12">
                <MegaMenu />
              </Popover.Dropdown>
            </Popover> */}

            <SideMenu getScoringPoints={getScoringPoints} />

            <Link
              to="/home"
              className="hover:-translate-y-1 transition ease-in-out duration-500"
            >
              <img
                src={Logo}
                alt="Logo"
                className="h-8 hover:-translate-y-1 transition ease-in-out duration-500"
              />
            </Link>
          </div>

          {/* Second Part: Links */}
          <div className="hidden lg:flex lg:items-center lg:justify-center space-x-5">
            <Link
              to="/articles"
              className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
            >
              Articles
            </Link>
            <Link
              to="/education"
              className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
            >
              Education
            </Link>
            {/* <Link
              to="/groups"
              className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
            >
              Groups
            </Link> */}
            <Link
              to="/coming-soon"
              className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
            >
              Events
            </Link>
            <Link
              to="/coming-soon"
              className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor"
            >
              Jobs
            </Link>
            {Cookies.get("access_token") && (
              <a
                target="_blank"
                href="https://old-website.medicaspace.com"
                className="text-main bg-submain p-2 rounded-3 font-paragraphFont font-semibold no-underline text-sm hover:text-white"
                rel="noopener noreferrer"
              >
                Old Website
              </a>
            )}
          </div>

          {/* Third Part: Search Icon and Buttons */}

          <div className="flex items-center space-x-4">
            <HeaderSearchInput />
            {user ? (
              <AfterLogin
                notifications={data?.data}
                getScoringPoints={getScoringPoints}
              />
            ) : (
              <>
                <span
                  onClick={() =>
                    navigate("/sign-in", { state: { from: location } })
                  }
                  className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor hidden lg:flex cursor-pointer"
                >
                  Sign In
                </span>
                <Link
                  to="/sign-up"
                  className="bg-main text-white font-paragraphFont font-semibold no-underline px-3 py-2 rounded-3xl hover:bg-hoverColor hidden lg:flex"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
