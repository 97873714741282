import { authApi } from "./Login/AuthApi";
import { combineReducers } from "@reduxjs/toolkit";
import { IPv4API } from "./withoutBASE_URL/IPv4_API";
import { ZoomMeetings } from "./withoutBASE_URL/ZoomMettings";

import { Articles } from "./Home/ArticlesApi";
import { GroupDiscussion } from "./Home/GroupsApi";
import { EventApi } from "./Home/EventsApi";
import authReducer from "./Auth/AuthSlice";
import { Newsletter } from "./Home/NewsletterApi";
import { VerficationLoginsApi } from "./Login/VerficationLoginsApi";
import { Search } from "./Search/SearchApi";
import { HCPApi } from "./ListingPages/HCPApi";
import { SpecialitiesApi } from "./Dropdowns/SpecialityApi";
import { Educations } from "./Home/EducationsApi";
import { NotificationApi } from "./Home/NotificationApi";
import { CompaniesApi } from "./Dropdowns/Companies";
import { MedicalCenterApi } from "./ListingPages/MedicalCenter";
import { UserNewsLetter } from "./NewsLetter/UserNewsLetter";
import { sharedAPI } from "./shared/API";
import { zoomMeetingReducer } from "../slices/zoomSlice";
import { ScoringPoints } from "./ScoringPoints/ScoringPointsApi";

const rootReducer = combineReducers({
  auth: authReducer,
  zoomSlice: zoomMeetingReducer,
  [VerficationLoginsApi.reducerPath]: VerficationLoginsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [IPv4API.reducerPath]: IPv4API.reducer,
  [ZoomMeetings.reducerPath]: ZoomMeetings.reducer,
  [Articles.reducerPath]: Articles.reducer,
  [GroupDiscussion.reducerPath]: GroupDiscussion.reducer,
  [EventApi.reducerPath]: EventApi.reducer,
  [Newsletter.reducerPath]: Newsletter.reducer,
  [Search.reducerPath]: Search.reducer,
  [HCPApi.reducerPath]: HCPApi.reducer,
  [MedicalCenterApi.reducerPath]: MedicalCenterApi.reducer,
  [SpecialitiesApi.reducerPath]: SpecialitiesApi.reducer,
  [CompaniesApi.reducerPath]: CompaniesApi.reducer,
  [Educations.reducerPath]: Educations.reducer,
  [NotificationApi.reducerPath]: NotificationApi.reducer,
  [UserNewsLetter.reducerPath]: UserNewsLetter.reducer,
  [sharedAPI.reducerPath]: sharedAPI.reducer,
  [ScoringPoints.reducerPath]: ScoringPoints.reducer,
});

export default rootReducer;
