import React from "react";
import Header from "../../../Header/header";
import Newsletter from "../../../NewSletter/Newsletter";
import Footer from "../../../Footer/Footer";
import FormsTabs from "./FormsTabs";
import { useLocation } from "react-router-dom";
import CourseForm from "./CourseForm";

export default function AddEducationForm() {
  const location = useLocation();
  const id = location?.state?.courseID;
  return (
    <>
      <Header />
      {id ? (
        <>
          <CourseForm />
        </>
      ) : (
        <FormsTabs />
      )}
      <Newsletter />
      <Footer />
    </>
  );
}
