export const validateImage = (file, setImageError) => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/gif"]; // Allowed image types

  // Check the file type
  if (!ALLOWED_TYPES.includes(file.type)) {
    setImageError(
      "Invalid file type. Please upload a JPEG, PNG, or GIF image."
    );
    return false;
  }

  // Check the file size
  if (file.size > MAX_FILE_SIZE) {
    setImageError(`File size exceeds 5MB. Please upload a smaller image.`);
    return false;
  }

  // Clear error if file is valid
  setImageError("");
  return true;
};
