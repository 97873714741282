import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const ScoringPoints = createApi({
  reducerPath: "ScoringPoints",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ScoringPoints"],

  endpoints: (builder) => ({
    getScoringPoints: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/profile/points`,
        params,
      }),
      providesTags: ["ScoringPoints"],
    }),

    getUserScoringPointsActions: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/profile/my-points`,
        params,
      }),
      providesTags: ["ScoringPoints"],
    }),

    getScoringPointsActions: builder.query({
      query: () => ({
        method: "GET",
        url: `/scoring-points`,
      }),
      providesTags: ["ScoringPoints"],
    }),
  }),
});

export const {
  useGetScoringPointsQuery,
  useGetUserScoringPointsActionsQuery,
  useGetScoringPointsActionsQuery,
  useLazyGetScoringPointsQuery,
} = ScoringPoints;
