import React from "react";
import { BsExclamationCircleFill } from "react-icons/bs";

export default function PremiumModalContent({ content, children, color }) {
  return (
    <>
      <BsExclamationCircleFill
        className={`text-${color} text-4xl text-center m-auto my-3`}
      />
      <h2 className={`text-${color} text-base font-bold font-title`}>
        Important Notice
      </h2>

      <p className="text-text text-sm font-paragarphFont text-center break-words mt-4">
        {content}
      </p>
      <div className="flex justify-center items-center gap-3">{children}</div>
    </>
  );
}
