import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const Articles = createApi({
  reducerPath: "Articles",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["LatestArticles",
    "Articles",
    "single",
    "reviews",
    "form",
    "tags",
    "category"
  ],

  endpoints: (builder) => ({
    latestArticles: builder.query({
      query: () => ({
        method: "GET",
        url: `/articles/latest`,
      }),
      providesTags: ["LatestArticles"],
    }),
    topRatedArticles: builder.query({
      query: () => ({
        method: "GET",
        url: `/articles/top-rated`,
      }),
      providesTags: ["Articles"],
    }),
    topRatedSearchArticles: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/articles`,
        params,
      }),
      providesTags: ["Articles"],
    }),
    singleArticle: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/article/${params}`,
        headers: {
          'Accept': 'application/json', 
        },
      }),
      providesTags: ["single"],
    }),
    tagsOfArticle: builder.query({
      query: () => ({
        method: "GET",
        url: `/articles/tags`,
      }),
      providesTags: ["tags"],
    }),
    categoriesOfArticle: builder.query({
      query: () => ({
        method: "GET",
        url: `/articles/categories`,
      }),
      providesTags: ["category"],
    }),
//----------------------------------------------------
    likesOnArticle: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/article/like/${params}`,
      }),
      invalidatesTags: ["single"],
    }),
    disLikesOnArticle: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/article/dislike/${params}`,
      }),
      invalidatesTags: ["single"],
    }),
//----------------------------------------------------
    addArticleToBookmark: builder.mutation({
      query: (article_id) => ({
        method: "POST",
        url: `/article/bookmarks/add?article_id=${article_id}`,
      }),
      invalidatesTags: ["single"],
    }),
    removeArticleFromBookmark: builder.mutation({
      query: (article_id) => ({
        method: "POST",
        url: `/article/bookmarks/delete?article_id=${article_id}`,
      }),
      invalidatesTags: ["single"],
    }),
//----------------------------------------------------
    fetchReviewsArticle: builder.query({
      query: ({ article_id, page }) => ({
        method: "GET",
        url: `/article/reviews?article_id=${article_id}&page=${page}`,
      }),
      providesTags: ["reviews"],
    }),
    addReviewArticle: builder.mutation({
      query: ({ article_id, review }) => ({
        method: 'POST',
        url: `/article/reviews?article_id=${article_id}`,
        body: review,
      }),
      invalidatesTags: ["single","reviews"],
    }),
    updateReviewArticle: builder.mutation({
      query: ({ article_id, review_id, review }) => ({
        method: 'POST',
        url: `/article/reviews/update?article_id=${article_id}&review_id=${review_id}`,
        body: review,
      }),
      invalidatesTags: ["single","reviews"],
    }),
    deleteReviewArticle: builder.mutation({
      query: ({ article_id, review_id }) => ({
        method: 'POST',
        url: `/article/reviews/delete?article_id=${article_id}&review_id=${review_id}`,
      }),
      invalidatesTags: ["single","reviews"],
    }),
//----------------------------------------------------
    createArticle: builder.mutation({
      query: (article) => {
        return {  
          method: 'POST',
          url: `/article/create`,
          body: article,
        };
      },
      extraOptions: { multipart: true }, 
      invalidatesTags: ["Articles", "form"],
      }
    ),
    updateArticle: builder.mutation({
      query: ({article_id, article}) => ({
        method: "POST",
        url: `/article/update/${article_id}`,
        body: article,
      }),
      extraOptions: { multipart: true }, 
      invalidatesTags: ["Articles","form"],
    }),
    deleteArticle: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/article/delete/${params}`,
      }),
      invalidatesTags: ["Articles","form"],
    }),
    draftsArticles: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/article/drafts`,
        params,
      }),
      providesTags: ["Articles"],
    }),
    saveArticleAsDraft: builder.mutation({
      query: (article) => {
        return {  
          method: 'POST',
          url: `/article/drafts/create`,
          body: article,
        };
      },
      extraOptions: { multipart: true }, 
      invalidatesTags: ["Articles", "form"],
      }
    ),
    publishDraftArticle: builder.mutation({
      query: (id) => {
        return {  
          method: 'POST',
          url: `/article/drafts/publish/${id}`,
        };
      },
      extraOptions: { multipart: true }, 
      invalidatesTags: ["Articles", "form"],
      }
    ),
//----------------------------------------------------
  }),
});

export const {
  useLatestArticlesQuery,
  useTopRatedArticlesQuery,
  useTopRatedSearchArticlesQuery,

  useSingleArticleQuery,
  useTagsOfArticleQuery,
  useCategoriesOfArticleQuery,

  useLikesOnArticleMutation,
  useDisLikesOnArticleMutation,

  useAddArticleToBookmarkMutation,
  useRemoveArticleFromBookmarkMutation,

  useFetchReviewsArticleQuery,
  useAddReviewArticleMutation,
  useUpdateReviewArticleMutation,
  useDeleteReviewArticleMutation,

  useCreateArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,

  useDraftsArticlesQuery,
  useSaveArticleAsDraftMutation,
  usePublishDraftArticleMutation,

} = Articles;
