import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const NotificationApi = createApi({
  reducerPath: "NotificationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Notification"],

  endpoints: (builder) => ({
    getNotificationsList: builder.query({
      query: () => ({
        url: `/notifications`,
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["Notification"],
    }),

    NotificationIsRead: builder.query({
      query: () => ({
        url: `/notifications/read`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["read"],
    }),
  }),
});

export const {
  useGetNotificationsListQuery,
  useNotificationIsReadQuery,
  useLazyNotificationIsReadQuery
} = NotificationApi;
