import { Tabs } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseForm from "./CourseForm";

export default function FormsTabs() {
  const navigate = useNavigate();
  const { tabValue } = useParams();

  const location = useLocation();
  const id = location?.state?.courseID;

  const tabData = [
    {
      value: "single-lesson",
      label: "  Add Course with Single Lesson",
      component: <CourseForm />,
    },
    {
      value: "multi-lessons",
      label: " Add Course with Multi-Modules & Multi-Lessons",
      component: <CourseForm />,
    },
  ];

  const visibleTabs = id ? tabData.slice(1) : tabData;

  const tabClasses = (value) =>
    `${
      tabValue === value
        ? "!bg-submain !text-white"
        : "!text-submain !bg-[#EFF8FF]"
    } !px-8 flex justify-center items-center !rounded-full !border-0 !text-xs font-paragarphFont font-semibold`;

  return (
    <>
      <Tabs
        keepMounted={false}
        value={tabValue}
        onChange={(value) => navigate(`/education-form/${value}`)}
        className="py-8 w-full px-12"
      >
        <Tabs.List className="before:!border-transparent !gap-6">
          {visibleTabs.map((tab) => (
            <Tabs.Tab
              key={tab.value}
              value={tab.value}
              className={tabClasses(tab.value)}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {visibleTabs.map(
          (tab) =>
            tabValue === tab.value && (
              <Tabs.Panel key={tab.value} value={tab.value}>
                {tab.component}
              </Tabs.Panel>
            )
        )}
      </Tabs>
    </>
  );
}
