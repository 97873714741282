import {
  Accordion,
  ActionIcon,
  FileInput,
  Image,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { HiOutlinePhoto } from "react-icons/hi2";
import { DatePickerInput } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { PiCurrencyDollar } from "react-icons/pi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../Context/authContext";
import {
  useCreateEducationMutation,
  useDeleteEducationLessonMutation,
  useDeleteEducationModuleMutation,
  useSingleEducationQuery,
  useUpdateEducationLessonMutation,
  useUpdateEducationModuleMutation,
  useUpdateEducationMutation,
  useUploadFilesMutation,
} from "../../../store/api/Home/EducationsApi";
import { BiSolidEdit, BiTrash } from "react-icons/bi";
import { generateSlug } from "../../../units/GenerateSlug";
import PremiumModal from "../../../Shared/Modals/PremiumModal";
import PremiumModalContent from "../../../Shared/SinglePages/PremiumModalContent";

import { useLazyGetScoringPointsQuery } from "../../../store/api/ScoringPoints/ScoringPointsApi";
import { BsTrash } from "react-icons/bs";
import { validateInput } from "../../../Shared/Functions/ValidateInputs";
import { validateImage } from "../../../Shared/Functions/ValidateImage";
import Loading from "../../../Loader/Loading";

export default function CourseForm() {
  const { user } = useAuth();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [triggerScoringPoints] = useLazyGetScoringPointsQuery();
  const navigate = useNavigate();
  const { tabValue } = useParams();

  const [previewImage, setPreviewImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadFileStatus, setUploadFileStatus] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCloseModal = () => {
    if (!isAgreed) {
      setValue("type", "free");
    }
    setConfirmModalOpen(false);
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setValue,
    reset,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const startDate = watch("start_date");
  const startAt = watch("start_at");
  const selectedType = watch("type");

  const [modules, setModules] = useState([{ id: "module-1", lessons: [{}] }]);

  const handleAddLesson = (moduleIndex) => {
    setModules((prevModules) => {
      if (!prevModules[moduleIndex]) {
        console.error(`Module at index ${moduleIndex} is undefined.`);
        return prevModules;
      }

      const updatedModules = [...prevModules];
      if (!updatedModules[moduleIndex].lessons) {
        updatedModules[moduleIndex].lessons = [{}];
      } else {
        updatedModules[moduleIndex].lessons.push({});
      }

      return updatedModules;
    });
  };

  const handleAddSection = () => {
    const newModuleId = `module-${modules.length + 1}`;
    setModules((prevModules) => [
      ...prevModules,
      { id: newModuleId, lessons: [{}] },
    ]);
  };

  const [AddCourse] = useCreateEducationMutation();
  const [UpdateEducation] = useUpdateEducationMutation();
  const [UpdateModule] = useUpdateEducationModuleMutation();
  const [UpdateLesson] = useUpdateEducationLessonMutation();
  const [UploadFiles] = useUploadFilesMutation();
  const { data: singleEducation } = useSingleEducationQuery(id, {
    skip: !id,
  });

  const handleFileUpload = async (
    file,
    moduleIndex,
    lessonIndex,
    typeId,
    fieldName
  ) => {
    if (!file || !typeId) return;

    const formData = new FormData();
    formData.append("file", file);

    if (typeId === "1") {
      formData.append("type", "file");
    } else if (typeId === "2") {
      formData.append("type", "video");
    } else {
      formData.append("type", "image");
    }

    setIsUploading(true);
    const key =
      typeId === "1" || typeId === "2"
        ? tabValue === "multi-lessons"
          ? `module-${moduleIndex}-lesson-${lessonIndex}`
          : `module-0-lesson-0`
        : null;
    try {
      if (key) {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [key]: "Uploading...",
        }));
      } else {
        setUploadStatus("Uploading...");
      }

      const response = await UploadFiles(formData).unwrap();

      if (fieldName === "image") {
        setValue(fieldName, response.data.file_path);
      } else {
        setValue(fieldName, response.data.file_path);
      }

      if (key) {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [key]: "Upload successful",
        }));
      } else {
        setUploadStatus("Upload successful");
      }
    } catch (error) {
      console.error("File upload failed:", error);
      if (key) {
        setUploadFileStatus((prevStatus) => ({
          ...prevStatus,
          [key]: "Upload failed. Please try again.",
        }));
      } else {
        setUploadStatus("Upload failed. Please try again.");
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (file, moduleIndex, lessonIndex) => {
    const lessonKey = `module-${
      tabValue === "multi-lessons" ? moduleIndex : 0
    }-lesson-${tabValue === "multi-lessons" ? lessonIndex : 0}`;
    const typeId = watch(`${lessonKey}-type_id`);

    if (!typeId) {
      setError(`${lessonKey}-file`, {
        type: "manual",
        message: "Please select the attachment type first.",
      });
      return;
    }

    const fieldName =
      tabValue === "multi-lessons"
        ? `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`
        : `module-0-lesson-0-attachment_url`;

    handleFileUpload(file, moduleIndex, lessonIndex, typeId, fieldName);
  };

  const onSubmit = async (data) => {
    try {
      if (data?.start_date) {
        const startDate = new Date(data?.start_date);
        data.start_date = startDate?.toLocaleDateString("en-CA");
      }

      if (data?.end_date) {
        const endDate = new Date(data?.end_date);
        data.end_date = endDate?.toLocaleDateString("en-CA");
      }

      const transformedModules = [];
      const transformedLessons = [];

      const additionalData = {
        speciality_id: user?.speciality?.[0]?.id,
        instructor_name: user?.name,
        education_id: id,
      };

      modules.forEach((module, moduleIndex) => {
        transformedModules.push({
          name: data[`module-${moduleIndex}-name`],
          description: data[`module-${moduleIndex}-description`],
        });

        module.lessons.forEach((_, lessonIndex) => {
          transformedLessons.push({
            name: data[`module-${moduleIndex}-lesson-${lessonIndex}-name`],
            lesson_details:
              data[
                `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`
              ],
            module_index: moduleIndex,
            type_id:
              data[`module-${moduleIndex}-lesson-${lessonIndex}-type_id`],
            lesson_duration:
              data[
                `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`
              ],
            attachment_url:
              data[
                `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`
              ],
          });
        });
      });

      const courseData = {
        name: data?.name,
        description: data?.description,
        start_date: data?.start_date || null,
        end_date: data?.end_date || null,
        type: data?.type || "free",
        price: data?.price || "",
        image: data?.image,
      };

      const educationeData = {
        ...additionalData,
        ...courseData,
        modules:
          tabValue === "multi-lessons"
            ? transformedModules
            : [
                {
                  name: data?.name,
                  description: data?.description,
                },
              ],
        lessons:
          tabValue === "multi-lessons"
            ? transformedLessons
            : [
                {
                  name: data[`module-0-lesson-0-name`],
                  lesson_details: data?.description,
                  module_index: 0,
                  type_id: data[`module-0-lesson-0-type_id`],
                  lesson_duration: data[`module-0-lesson-0-lesson_duration`],
                  attachment_url: data[`module-0-lesson-0-attachment_url`],
                },
              ],
      };

      const updatedCourseData = { ...data, ...additionalData };

      if (id && singleEducation) {
        const result = await UpdateEducation(updatedCourseData).unwrap();

        if (result?.id) {
          navigate(`/course/${generateSlug(result?.name)}?id=${result?.id}`, {
            state: { courseID: result?.id },
          });
        }
      } else {
        const result = await AddCourse(educationeData).unwrap();
        if (result?.education?.id) {
          await triggerScoringPoints();
          navigate(
            `/course/${generateSlug(result?.education?.name)}?id=${
              result?.education?.id
            }`,
            {
              state: { courseID: result?.education?.id },
            }
          );
        }
      }
      reset();
    } catch (error) {
      console.error("Failed to add education:", error);
    }
  };

  const [DeleteLesson] = useDeleteEducationLessonMutation();
  const [DeleteModule] = useDeleteEducationModuleMutation();
  const [deletingId, setDeletingId] = useState(null);

  const handleDeleteModule = async (module_id) => {
    setDeletingId(module_id);
    try {
      const educ_id = singleEducation?.id;
      const formData = new FormData();
      formData.append("module_id", module_id);
      formData.append("education_id", educ_id);
      await DeleteModule(formData).unwrap();
      reset();
    } catch (error) {
      console.error(`Failed to delete course:`, error);
    } finally {
      setDeletingId(null);
    }
  };

  const handleDeleteLesson = async (lesson_id) => {
    setDeletingId(lesson_id);
    try {
      const educ_id = singleEducation?.id;
      const formData = new FormData();
      formData.append("lesson_id", lesson_id);
      formData.append("education_id", educ_id);
      await DeleteLesson(formData).unwrap();
    } catch (error) {
      console.error(`Failed to delete course:`, error);
    } finally {
      setDeletingId(null);
    }
  };

  const onUpdateModule = async (moduleIndex, moduleID) => {
    try {
      const moduleData = {
        name: getValues(`module-${moduleIndex}-name`),
        description: getValues(`module-${moduleIndex}-description`),
      };

      if (singleEducation) {
        await UpdateModule({
          education_id: id,
          module_id: moduleID,
          ...moduleData,
        }).unwrap();
      }
    } catch (error) {
      console.error("Failed to update module:", error);
    }
  };

  const onUpdateLesson = async (moduleIndex, lessonIndex, lessonID) => {
    try {
      const lessonData = {
        name: getValues(`module-${moduleIndex}-lesson-${lessonIndex}-name`),
        lesson_details: getValues(
          `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`
        ),
        type_id: getValues(
          `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
        ),
        attachment_url: getValues(
          `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`
        ),
        lesson_duration: getValues(
          `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`
        ),
        module_index: moduleIndex,
      };

      if (singleEducation) {
        await UpdateLesson({
          education_id: id,
          lesson_id: lessonID,
          ...lessonData,
        }).unwrap();
      }
    } catch (error) {
      console.error("Failed to update module:", error);
    }
  };

  useEffect(() => {
    if (singleEducation) {
      reset({
        name: singleEducation?.name || "",
        description: singleEducation?.description || "",
        type: singleEducation?.type || null,
        price: singleEducation?.price || "",
        start_at: singleEducation?.start_at
          ? new Date(singleEducation.start_at)
          : null,
        end_date: singleEducation?.end_date
          ? new Date(singleEducation.end_date)
          : null,
        image: singleEducation?.image || null,
      });

      if (singleEducation?.image && singleEducation.image instanceof File) {
        setPreviewImage(URL.createObjectURL(singleEducation?.image));
      } else if (typeof singleEducation?.image === "string") {
        setPreviewImage(singleEducation?.image);
      }
      if (singleEducation?.Modules?.data?.length) {
        singleEducation?.Modules?.data?.forEach((module, moduleIndex) => {
          setValue(`module-${moduleIndex}-name`, module?.name || "");
          setValue(
            `module-${moduleIndex}-description`,
            module?.description || ""
          );

          // Loop through all lessons for each module
          if (module?.lessons?.data?.length) {
            module?.lessons?.data?.forEach((lesson, lessonIndex) => {
              setValue(
                `module-${moduleIndex}-lesson-${lessonIndex}-name`,
                lesson?.name || ""
              );
              setValue(
                `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`,
                lesson?.lesson_details || ""
              );
              setValue(
                `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`,
                lesson?.lesson_duration || ""
              );
              setValue(
                `module-${moduleIndex}-lesson-${lessonIndex}-type_id`,
                lesson?.type_id === "video"
                  ? "2"
                  : lesson?.type_id === "file"
                  ? "1"
                  : "4"
              );

              if (lesson?.attachment_url && lesson?.type_id === "url") {
                setValue(
                  `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`,
                  lesson.attachment_url
                );
              }
            });
          }

          if (singleEducation?.Modules?.data?.length) {
            const formattedModules = singleEducation?.Modules?.data.map(
              (module) => ({
                id: module?.id,
                name: module?.name,
                description: module?.description,
                lessons: module?.lessons?.data?.map((lesson) => ({
                  id: lesson.id,
                  name: lesson?.name,
                  lesson_details: lesson?.lesson_details,
                  lesson_duration: lesson?.lesson_duration,
                  type_id: lesson?.type_id,
                  attachment_url: lesson?.attachment_url,
                })),
              })
            );
            setModules(formattedModules);
          }
        });
      }
    }
  }, [id, reset, singleEducation, setValue]);

  useEffect(() => {
    if (
      (selectedType === "premium" || selectedType === "paid") &&
      !singleEducation
    ) {
      setConfirmModalOpen(true);
      setIsAgreed(false);
    }
  }, [selectedType]);

  return (
    <>
      <section className="py-28 px-20 w-full max-lg:px-2 sm:max-lg:px-20">
        <div className="w-full">
          <h4 className="text-hoverColor text-2xl font-bold font-title mb-2">
            {id && singleEducation ? "Update Course" : " New Course"}
          </h4>
          <form
            className="mt-4"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="flex flex-col-reverse justify-between lg:flex-row lg:items-start lg:gap-8 w-full">
              <div className="flex flex-col w-full lg:w-2/3">
                {/* Education Details */}
                <div className="flex flex-col w-full">
                  {/* Title */}
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please insert course title",
                        },
                        validate: (value) => {
                          const validationError = validateInput(value);
                          if (validationError) {
                            return validationError;
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          label="Title"
                          placeholder="Add Course Title"
                          className="w-full"
                          classNames={{ input: "py-4 !rounded-lg w-full" }}
                          error={errors?.name?.message}
                        />
                      )}
                    />
                  </div>

                  {/* Description */}
                  <div>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please insert course description.",
                        },
                        validate: (value) => {
                          const validationError = validateInput(value);
                          if (validationError) {
                            return validationError;
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          mt="xl"
                          label="Description"
                          placeholder="Add Course Description Here.."
                          className="w-full "
                          classNames={{ input: "py-4 !rounded-lg w-full" }}
                          error={errors?.description?.message}
                        />
                      )}
                    />
                  </div>
                  {/* Date */}
                  <div className="flex flex-col justify-between items-center md:flex-row gap-4">
                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                      <Controller
                        name={id && singleEducation ? "start_at" : "start_date"}
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name={
                              id && singleEducation ? "start_at" : "start_date"
                            }
                            label="Starting Date "
                            placeholder="Start Date"
                            className="w-full"
                            clearable
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={
                              errors?.start_date?.message ||
                              errors?.start_at?.message
                            }
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row  gap-4 w-full">
                      <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name="end_date"
                            label="Ending Date "
                            placeholder="End Date"
                            className="w-full"
                            clearable
                            minDate={startDate || startAt || null}
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={errors?.end_date?.message}
                          />
                        )}
                        rules={{
                          validate: {
                            notEqualToStartDate: (value) =>
                              !value ||
                              !startDate ||
                              !startAt ||
                              value >= startDate ||
                              startAt ||
                              "End date cannot be earlier than start date.",
                            notSameAsStartDate: (value) =>
                              !value ||
                              !startDate ||
                              !startAt ||
                              value.toLocaleDateString("en-CA") !==
                                startDate.toLocaleDateString("en-CA") ||
                              startAt.toLocaleDateString("en-CA") ||
                              "End date cannot be the same as start date.",
                          },
                        }}
                      />
                    </div>
                  </div>

                  {/* Category and price */}
                  {user && user?.is_premium === 1 && (
                    <div className=" flex flex-col justify-between items-center md:flex-row gap-4">
                      <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                        <Controller
                          name="type"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: `Please insert course category`,
                            },
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              mt="xl"
                              name="type"
                              label="Category"
                              placeholder="Add Course Category.."
                              data={[
                                { value: "free", label: "Free" },
                                { value: "paid", label: "Paid" },
                                { value: "premium", label: "Premium" },
                              ]}
                              clearable
                              searchable
                              rightSectionWidth={30}
                              className="w-full"
                              classNames={{
                                input: "!py-6 !rounded-lg !w-full",
                              }}
                              allowDeselect={false}
                              error={errors?.type?.message}
                            />
                          )}
                        />
                      </div>

                      {selectedType === "paid" && (
                        <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                          <Controller
                            name="price"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Please insert course price.",
                              },
                              pattern: {
                                value: /^\d*$/,
                                message: "Only numeric values are allowed",
                              },
                            }}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                mt="xl"
                                label="Course Price"
                                placeholder="Add Course price"
                                className="w-full"
                                classNames={{
                                  input: "py-4 !rounded-lg !w-full",
                                }}
                                leftSection={<PiCurrencyDollar size={16} />}
                                error={errors?.price?.message}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {singleEducation && (
                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      type="submit"
                      disabled={!isValid || isUploading}
                      className={`text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
                ${
                  !isValid || isUploading ? "opacity-50 cursor-not-allowed" : ""
                }
                    `}
                    >
                      <BiSolidEdit /> Update Course
                    </button>
                  </div>
                )}

                {/* Chapter & Lessons */}
                {tabValue === "multi-lessons" ? (
                  <>
                    {deletingId ? (
                      <div className="mt-4 flex justify-center items-center">
                        <Loading isLoading={true} />
                      </div>
                    ) : (
                      <Accordion
                        variant="separated"
                        defaultValue="module-0"
                        className={`mt-4 ${
                          singleEducation &&
                          singleEducation?.Modules?.data?.length === 0
                            ? "hidden"
                            : "block"
                        }`}
                      >
                        {/* Module Accordion */}
                        {modules.map((module, moduleIndex) => (
                          <Accordion.Item
                            key={moduleIndex}
                            value={`module-${moduleIndex}`}
                            hidden={deletingId === module?.id}
                          >
                            <Accordion.Control>
                              <div className=" w-full">
                                <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                                  {" "}
                                  New Module
                                </h5>
                              </div>
                            </Accordion.Control>

                            <Accordion.Panel>
                              <Controller
                                name={`module-${moduleIndex}-name`}
                                control={control}
                                rules={{
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message: "Please insert module title",
                                  },
                                  validate: (value) => {
                                    const validationError =
                                      validateInput(value);
                                    if (validationError) {
                                      return validationError;
                                    }
                                    return true;
                                  },
                                }}
                                render={({ field }) => (
                                  <TextInput
                                    {...field}
                                    mt="lg"
                                    label="Module Title"
                                    placeholder="Add Module Title"
                                    className="w-full"
                                    classNames={{
                                      input: "py-4 !rounded-lg w-full",
                                    }}
                                    error={
                                      errors?.[`module-${moduleIndex}-name`]
                                        ?.message
                                    }
                                  />
                                )}
                              />

                              <Controller
                                name={`module-${moduleIndex}-description`}
                                control={control}
                                rules={{
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message: "Please insert module description",
                                  },
                                  validate: (value) => {
                                    const validationError =
                                      validateInput(value);
                                    if (validationError) {
                                      return validationError;
                                    }
                                    return true;
                                  },
                                }}
                                render={({ field }) => (
                                  <TextInput
                                    {...field}
                                    mt="xl"
                                    label="Module Description"
                                    placeholder="Add Module Description Here.."
                                    className="w-full"
                                    classNames={{
                                      input: "py-4 !rounded-lg w-full",
                                    }}
                                    error={
                                      errors?.[
                                        `module-${moduleIndex}-description`
                                      ]?.message
                                    }
                                  />
                                )}
                              />

                              {singleEducation && (
                                <div className="w-full flex justify-end items-center gap-x-4">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      onUpdateModule(moduleIndex, module?.id)
                                    }
                                    disabled={!isValid}
                                    className={`mt-4 text-white bg-main text-xs px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
                ${!isValid ? "opacity-50 cursor-not-allowed" : ""}
                    `}
                                  >
                                    <BiSolidEdit />
                                    Update Module
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleDeleteModule(module?.id);
                                    }}
                                    className={`mt-4 text-white bg-[#f03e3e] text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
               
                    `}
                                  >
                                    <BsTrash />
                                    Delete Module
                                  </button>
                                </div>
                              )}
                            </Accordion.Panel>

                            {module.lessons.map((_, lessonIndex) => (
                              <Accordion.Panel key={`lesson-${lessonIndex}`}>
                                <div className="lesson ms-8 ps-8 border-l-2 border-[#f5f5f5]">
                                  <div className="w-full mb-4 mt-4 flex justify-between items-center ">
                                    <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                                      New Lesson
                                    </h5>
                                  </div>
                                  <div>
                                    <Controller
                                      name={`module-${moduleIndex}-lesson-${lessonIndex}-name`}
                                      control={control}
                                      rules={{
                                        required: {
                                          value:
                                            id && singleEducation
                                              ? false
                                              : true,
                                          message:
                                            "Please insert your lesson title",
                                        },
                                        validate: (value) => {
                                          const validationError =
                                            validateInput(value);
                                          if (validationError) {
                                            return validationError;
                                          }
                                          return true;
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          label="Lesson Title"
                                          placeholder="Add Lesson Title"
                                          className="w-full"
                                          classNames={{
                                            input: "py-4 !rounded-lg w-full",
                                          }}
                                          error={
                                            errors?.[
                                              `module-${moduleIndex}-lesson-${lessonIndex}-name`
                                            ]?.message
                                          }
                                        />
                                      )}
                                    />

                                    <Controller
                                      name={`module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`}
                                      control={control}
                                      rules={{
                                        required: {
                                          value:
                                            id && singleEducation
                                              ? false
                                              : true,
                                          message:
                                            "Please insert your lesson description",
                                        },
                                        validate: (value) => {
                                          const validationError =
                                            validateInput(value);
                                          if (validationError) {
                                            return validationError;
                                          }
                                          return true;
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextInput
                                          {...field}
                                          mt="xl"
                                          label="Lesson Description"
                                          placeholder="Add Lesson Description"
                                          className="w-full "
                                          classNames={{
                                            input: "py-4 !rounded-lg w-full",
                                          }}
                                          error={
                                            errors?.[
                                              `module-${moduleIndex}-lesson-${lessonIndex}-lesson_details`
                                            ]?.message
                                          }
                                        />
                                      )}
                                    />
                                    <Controller
                                      name={`module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`}
                                      control={control}
                                      rules={{
                                        required: {
                                          value:
                                            id && singleEducation
                                              ? false
                                              : true,
                                          message:
                                            "Please insert your lesson duration",
                                        },
                                      }}
                                      render={({ field }) => (
                                        <NumberInput
                                          {...field}
                                          mt="xl"
                                          label="Lesson Duration"
                                          placeholder="Enter Duration"
                                          className="w-full "
                                          classNames={{
                                            input: "py-4 !rounded-lg w-full",
                                          }}
                                          error={
                                            errors?.[
                                              `module-${moduleIndex}-lesson-${lessonIndex}-lesson_duration`
                                            ]?.message
                                          }
                                        />
                                      )}
                                    />

                                    <Controller
                                      name={`module-${moduleIndex}-lesson-${lessonIndex}-type_id`}
                                      control={control}
                                      rules={{
                                        required: {
                                          value:
                                            id && singleEducation
                                              ? false
                                              : true,
                                          message: `Please select your content type`,
                                        },
                                      }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          mt="xl"
                                          label="Select Attachment Type"
                                          placeholder="Select the content type..."
                                          data={[
                                            { value: "1", label: "File" },
                                            { value: "2", label: "Video" },
                                            { value: "4", label: "URL" },
                                          ]}
                                          clearable
                                          searchable
                                          rightSectionWidth={30}
                                          className="w-full"
                                          classNames={{
                                            input: "!py-6 !rounded-lg !w-full",
                                          }}
                                          onChange={(value) => {
                                            field.onChange(value);
                                            clearErrors(`file`);
                                          }}
                                          allowDeselect={false}
                                          error={
                                            errors?.[
                                              `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                            ]?.message
                                          }
                                        />
                                      )}
                                    />

                                    {watch(
                                      `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                    ) && (
                                      <>
                                        {watch(
                                          `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                        ) === "4" ? (
                                          <Controller
                                            name={`module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`}
                                            control={control}
                                            rules={{
                                              required: {
                                                value:
                                                  id && singleEducation
                                                    ? false
                                                    : true,
                                                message:
                                                  "Please insert your lesson attachment URL",
                                              },
                                              pattern: {
                                                value:
                                                  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
                                                message:
                                                  "Please enter a valid URL",
                                              },

                                              validate: {
                                                typeSelected: () => {
                                                  const typeId = watch(
                                                    `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                                  );
                                                  return typeId
                                                    ? true
                                                    : "You must select the attachment type first, to accept the URL.";
                                                },
                                              },
                                            }}
                                            render={({ field }) => (
                                              <TextInput
                                                {...field}
                                                label="Attachment URL"
                                                type="url"
                                                placeholder="https://example.com"
                                                className="w-full mt-4"
                                                classNames={{
                                                  input:
                                                    "py-4 !rounded-lg w-full",
                                                }}
                                                error={
                                                  errors?.[
                                                    `module-${moduleIndex}-lesson-${lessonIndex}-attachment_url`
                                                  ]?.message
                                                }
                                              />
                                            )}
                                          />
                                        ) : (
                                          <>
                                            <Controller
                                              name={`file`}
                                              control={control}
                                              {...register(
                                                `module-${moduleIndex}-lesson-${lessonIndex}-file`,
                                                {
                                                  validate: {
                                                    typeSelected: () => {
                                                      const typeId = watch(
                                                        `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                                      );
                                                      return typeId
                                                        ? true
                                                        : "You must select the attachment type first, to upload.";
                                                    },
                                                  },
                                                }
                                              )}
                                              render={({ field }) => (
                                                <FileInput
                                                  {...field}
                                                  label="Upload File / Attachment"
                                                  placeholder={
                                                    "Upload your course attachment"
                                                  }
                                                  description={
                                                    watch(
                                                      `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                                    ) === "2"
                                                      ? "You can upload videos"
                                                      : watch(
                                                          `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                                        ) === "1"
                                                      ? "You can upload docs | attachment | ppt | pdf files"
                                                      : "upload file attachments"
                                                  }
                                                  accept={
                                                    watch(
                                                      `module-${moduleIndex}-lesson-${lessonIndex}-type_id`
                                                    ) === "2"
                                                      ? "video/mp4, video/mov, video/avi, video/mkv, video/webm"
                                                      : ".pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .txt,  .xlsx , .xls"
                                                  }
                                                  className="mt-4"
                                                  classNames={{
                                                    input:
                                                      "!rounded-lg w-full py-3",
                                                  }}
                                                  onChange={(file) => {
                                                    field.onChange(file);
                                                    handleFileChange(
                                                      file,
                                                      moduleIndex,
                                                      lessonIndex
                                                    );
                                                  }}
                                                  error={
                                                    errors?.[
                                                      `module-${moduleIndex}-lesson-${lessonIndex}-file`
                                                    ]?.message
                                                  }
                                                />
                                              )}
                                            />
                                            {uploadFileStatus[
                                              `module-${moduleIndex}-lesson-${lessonIndex}`
                                            ] && (
                                              <span className="text-sm text-hoverColor font-paragarphFont mt-2">
                                                {
                                                  uploadFileStatus[
                                                    `module-${moduleIndex}-lesson-${lessonIndex}`
                                                  ]
                                                }
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>

                                {singleEducation && (
                                  <div className="w-full flex justify-end items-center gap-x-4">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        onUpdateLesson(
                                          moduleIndex,
                                          lessonIndex,
                                          _?.id
                                        )
                                      }
                                      disabled={!isValid || isUploading}
                                      className={`mt-4 text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
                ${
                  !isValid || isUploading ? "opacity-50 cursor-not-allowed" : ""
                }
                    `}
                                    >
                                      <BiSolidEdit />
                                      Update Lesson
                                    </button>

                                    <button
                                      type="button"
                                      onClick={() => handleDeleteLesson(_?.id)}
                                      className="mt-4 text-white text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto bg-[#f03e3e]
                
                    "
                                    >
                                      <BsTrash />
                                      Delete Lesson
                                    </button>
                                  </div>
                                )}
                              </Accordion.Panel>
                            ))}

                            {/* Add Lesson BTN */}
                            <Accordion.Panel>
                              <div
                                className={`my-4 px-12 text-main text-xl font-paragarphFont font-semibold flex justify-center items-center gap-1 w-full
              sm:text-base sm:w-auto before:h-0 before:m-2 before:border before:border-main before:flex-1 after:h-0 after:m-2 after:border after:border-main after:flex-1 
              ${
                id
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer hover:text-hoverColor"
              } transition ease-in-out duration-300`}
                                onClick={() => {
                                  if (!id) {
                                    handleAddLesson(moduleIndex);
                                  }
                                }}
                              >
                                <IoIosAddCircleOutline /> Add a New Lesson
                              </div>
                            </Accordion.Panel>
                          </Accordion.Item>
                        ))}
                        <div
                          className={`mt-4 text-main text-xl font-paragarphFont font-semibold flex justify-center items-center gap-1 w-full
                      sm:text-base sm:w-auto before:h-0 before:m-2 before:border before:border-main before:flex-1 after:h-0 after:m-2 after:border after:border-main after:flex-1 
                      ${
                        id
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer hover:text-hoverColor"
                      } transition ease-in-out duration-300`}
                          onClick={() => {
                            if (!id) {
                              handleAddSection();
                            }
                          }}
                        >
                          <IoIosAddCircleOutline /> Add a New Section
                        </div>
                      </Accordion>
                    )}
                  </>
                ) : (
                  <>
                    <div className="mt-4">
                      <Controller
                        name={`module-0-lesson-0-name`}
                        control={control}
                        rules={{
                          required: {
                            value:
                              id || tabValue !== "single-lesson" ? false : true,
                            message: "Please insert your lesson title",
                          },
                          validate: (value) => {
                            const validationError = validateInput(value);
                            if (validationError) {
                              return validationError;
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            label="Lesson Title"
                            placeholder="Add Lesson Title"
                            className="w-full"
                            classNames={{
                              input: "py-4 !rounded-lg w-full",
                            }}
                            error={errors?.[`module-0-lesson-0-name`]?.message}
                          />
                        )}
                      />

                      <Controller
                        name={`module-0-lesson-0-lesson_duration`}
                        control={control}
                        rules={{
                          required: {
                            value: id && singleEducation ? false : true,
                            message: "Please insert your lesson duration",
                          },
                        }}
                        render={({ field }) => (
                          <NumberInput
                            {...field}
                            mt="xl"
                            label="Lesson Duration"
                            placeholder="Enter Duration"
                            className="w-full "
                            classNames={{
                              input: "py-4 !rounded-lg w-full",
                            }}
                            error={
                              errors?.[`module-0-lesson-0-lesson_duration`]
                                ?.message
                            }
                          />
                        )}
                      />

                      <Controller
                        name={`module-0-lesson-0-type_id`}
                        control={control}
                        rules={{
                          required: {
                            value:
                              id || tabValue !== "single-lesson" ? false : true,
                            message: `Please select your content type`,
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            mt="xl"
                            label="Select Attachment Type"
                            placeholder="Select the content type..."
                            data={[
                              { value: "1", label: "File" },
                              { value: "2", label: "Video" },
                              { value: "4", label: "URL" },
                            ]}
                            clearable
                            searchable
                            rightSectionWidth={30}
                            className="w-full"
                            classNames={{
                              input: "!py-6 !rounded-lg !w-full",
                            }}
                            onChange={(value) => {
                              field.onChange(value);
                              clearErrors(`module-0-lesson-0-file`);
                            }}
                            allowDeselect={false}
                            error={
                              errors?.[`module-0-lesson-0-type_id`]?.message
                            }
                          />
                        )}
                      />

                      {watch(`module-0-lesson-0-type_id`) && (
                        <>
                          {watch(`module-0-lesson-0-type_id`) === "4" ? (
                            <Controller
                              name={`module-0-lesson-0-attachment_url`}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    id || tabValue !== "single-lesson"
                                      ? false
                                      : true,
                                  message:
                                    "Please insert your lesson attachment URL",
                                },
                                pattern: {
                                  value:
                                    /^(https?:\/\/)?([a-zA-Z0-9.-])+(:\d+)?(\/.*)?$/,
                                  message: "Please enter a valid URL",
                                },

                                validate: {
                                  typeSelected: () => {
                                    const typeId = watch(
                                      `module-0-lesson-0-type_id`
                                    );
                                    return typeId
                                      ? true
                                      : "You must select the attachment type first, to accept the URL.";
                                  },
                                },
                              }}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  label="Attachment URL"
                                  type="url"
                                  placeholder="https://example.com"
                                  className="w-full mt-4"
                                  classNames={{
                                    input: "py-4 !rounded-lg w-full",
                                  }}
                                  error={
                                    errors?.[`module-0-lesson-0-attachment_url`]
                                      ?.message
                                  }
                                />
                              )}
                            />
                          ) : (
                            <>
                              <Controller
                                name={`module-0-lesson-0-file`}
                                control={control}
                                {...register(`module-0-lesson-0-file`, {
                                  validate: {
                                    typeSelected: () => {
                                      const typeId = watch(
                                        `module-0-lesson-0-type_id`
                                      );
                                      return typeId
                                        ? true
                                        : "You must select the attachment type first, to upload.";
                                    },
                                  },
                                })}
                                render={({ field }) => (
                                  <FileInput
                                    {...field}
                                    label="Upload File / Attachment"
                                    placeholder={
                                      "Upload your course attachment"
                                    }
                                    description={
                                      watch(`module-0-lesson-0-type_id`) === "2"
                                        ? "You can upload videos"
                                        : watch(`module-0-lesson-0-type_id`) ===
                                          "1"
                                        ? "You can upload docs | attachment | ppt | pdf files"
                                        : "upload file attachments"
                                    }
                                    accept={
                                      watch(`module-0-lesson-0-type_id`) === "2"
                                        ? "video/mp4, video/mov, video/avi, video/mkv, video/webm"
                                        : ".pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .txt,  .xlsx , .xls"
                                    }
                                    className="mt-4"
                                    classNames={{
                                      input: "!rounded-lg w-full py-3",
                                    }}
                                    onChange={(file) => {
                                      field.onChange(file);
                                      handleFileChange(file);
                                    }}
                                    error={
                                      errors?.[`module-0-lesson-0-file`]
                                        ?.message
                                    }
                                  />
                                )}
                              />

                              {uploadFileStatus[`module-0-lesson-0`] && (
                                <span className="text-sm text-hoverColor font-paragarphFont mt-2">
                                  {uploadFileStatus[`module-0-lesson-0`]}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* image */}
              <div
                className="flex flex-col justify-end items-start
               w-full lg:w-auto mb-12"
              >
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <>
                      {!previewImage ? (
                        <FileInput
                          name="image"
                          {...field}
                          label="Course Image"
                          placeholder={<HiOutlinePhoto size={200} />}
                          accept="image/*"
                          classNames={{ input: "!rounded-lg w-auto" }}
                          onChange={(file) => {
                            if (file && validateImage(file, setImageError)) {
                              field.onChange(file);
                              if (file) {
                                const imageUrl = URL.createObjectURL(file);
                                setPreviewImage(imageUrl);
                                handleFileUpload(
                                  file,
                                  null,
                                  null,
                                  "image",
                                  "image"
                                );
                              } else {
                                setPreviewImage(null);
                                setImageUrl("");
                              }
                            }
                          }}
                          error={errors?.image?.message}
                        />
                      ) : (
                        <div className="mt-4 h-44">
                          <Image
                            src={previewImage}
                            alt="Image Preview"
                            className="w-full h-100 max-w-xs !rounded-lg"
                          />
                          <ActionIcon
                            size={32}
                            variant="default"
                            mt={"xl"}
                            onClick={() => {
                              setPreviewImage(null);
                              setUploadStatus("");
                              field.onChange(null);
                            }}
                          >
                            <BiTrash size={16} color="red" />
                          </ActionIcon>
                        </div>
                      )}
                    </>
                  )}
                />

                {uploadStatus && (
                  <span className="text-sm text-hoverColor font-paragarphFont mt-2">
                    {uploadStatus}
                  </span>
                )}
                {imageError && (
                  <Text color="red" size="sm" className="mt-2">
                    {imageError}
                  </Text>
                )}
              </div>
            </div>

            {!singleEducation && (
              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="submit"
                  disabled={!isValid || isUploading}
                  className={`text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto
                ${
                  !isValid || isUploading ? "opacity-50 cursor-not-allowed" : ""
                }
                    `}
                >
                  <IoIosAddCircleOutline /> Add New Course
                </button>
              </div>
            )}
          </form>
        </div>
      </section>

      <PremiumModal opened={confirmModalOpen} onClose={handleCloseModal}>
        <PremiumModalContent
          content={`Dear Valued Memeber, Kindly note that editing a ${selectedType} education
        that is published is Limited at any time pending a request that is
        automatically sent to our administrative team for review of content
        modifications. Thank You for understanding.`}
          color={"hoverColor"}
        >
          <button
            className="text-white bg-main rounded-full w-1/3 text-center py-2 mt-3 font-sans font-bold text-base max-[350px]:text-sm hover:bg-hoverColor mb-4"
            onClick={() => {
              setIsAgreed(true);
              setConfirmModalOpen(false);
            }}
          >
            Agree
          </button>
        </PremiumModalContent>
      </PremiumModal>
    </>
  );
}
