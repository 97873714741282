import { Avatar, Menu, ScrollArea } from "@mantine/core";
import React, { useState } from "react";
import { useAuth } from "../Context/authContext";
import "./header.css";
import { BsFillCaretDownFill } from "react-icons/bs";

import avatar from "../assets/images/avatar.png";
import { useNavigate } from "react-router-dom";
import { generateSlug } from "../units/GenerateSlug";
export default function UserMenu() {
  const userNavigate = {
    HCP: "hcp-profile",
    Center: "mc-profile",
    Company: "hcc-profile",
  };

  const [openedMenu, setOpenedMenu] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const userAvatar = localStorage.getItem("userAvatar");
  const logout = async (e) => {
    e.preventDefault();
    await signOut();
    navigate("/home");
  };

  return (
    <>
      <div className="user-profile-image flex justify-content-center items-center gap-2">
        <Avatar
          src={userAvatar || avatar}
          alt="user-profile-image"
          className="border-2 cursor-pointer"
          style={{ borderColor: "var(--basic-color)" }}
          onClick={() =>
            navigate(
              `/${userNavigate[user?.user_type]}/${generateSlug(
                user?.name
              )}?id=${user?.id}`,
              {
                state: { userID: user?.id },
              }
            )
          }
        />
        <Menu
          shadow="md"
          width={200}
          position="bottom-end"
          offset={1}
          withArrow
          arrowPosition="center"
          opened={openedMenu}
          onChange={setOpenedMenu}
        >
          <Menu.Target>
            <div className="user-profile-image flex justify-content-center items-center gap-2">
              <BsFillCaretDownFill className="text-main text-xl cursor-pointer font-semibold font-paragraphFont hover:color-hoverColor mt-3" />
            </div>
          </Menu.Target>

          <Menu.Dropdown
            className="usermenu-drop"
            style={{ top: "70.5px", right: "8px", left: "unset" }}
          >
            <ScrollArea h={300} type="hover" scrollbarSize={6}>
              <Menu.Label className="px-2 !text-main">User</Menu.Label>
              <div className="py-2 px-2.5 font-medium text-sm	 text-submain">
                {user?.name}
              </div>
              <div className="py-2 px-2.5 font-medium text-sm	 text-submain">
                {user?.speciality?.[0]?.name}
              </div>
              {user?.country?.[0] && (
                <div className="py-2 px-2.5 font-medium text-sm	 text-submain">
                  {user?.country}
                </div>
              )}
              <div className="py-2 px-2.5 font-medium text-sm	 text-submain">
                Basic Profile
              </div>
              {/* <Menu.Divider /> */}
              <Menu.Label className="!text-main">User Actions</Menu.Label>

              <Menu.Item
                onClick={() =>
                  navigate(
                    `/${userNavigate[user?.user_type]}/${generateSlug(
                      user?.name
                    )}?id=${user?.id}`,
                    {
                      state: { userID: user?.id },
                    }
                  )
                }
              >
                Edit My Profile
              </Menu.Item>
              {/* <Menu.Item
            onClick={() =>
              navigate(
                `/${userNavigate[user?.user_type]}/${generateSlug(
                  user?.name
                )}?id=${user?.id}&tab=Community`
              )
            }
          >
            My Groups
          </Menu.Item> */}

              <Menu.Item onClick={() => navigate("/hcp-dashboard")}>
                Dashboard
              </Menu.Item>

              {/* <Menu.Item>Invite</Menu.Item>
          <Menu.Item>Add Account</Menu.Item>
          <Menu.Item>Switch Account</Menu.Item>
          <Menu.Item>Upgrade Premium</Menu.Item> */}

              {/* <Menu.Divider /> */}
              {/* <Menu.Label className="!text-main">Settings</Menu.Label>
          <Menu.Item>Help & Support</Menu.Item>
          <Menu.Item>Billing & Payment</Menu.Item>
          <Menu.Item>Ballance</Menu.Item>
          <Menu.Item>Scoring Points</Menu.Item>
          <Menu.Item>Send Feedback</Menu.Item> */}

              <Menu.Item color="red" onClick={logout}>
                Logout
              </Menu.Item>
            </ScrollArea>
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
}
